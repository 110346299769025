import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const UserRideBookingDetails = () => {

const [show, setShow] = useState(false);
const [userRideBookingIdToDelete, setUserRideBookingIdToDelete] = useState(null);

const navigate = useNavigate();
const [rideBookings, setRideBookings] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const { apipath } = useAuth();

const handleClose = () => setShow(false);
const dataTableRef = useRef();

const handleShow = (id) => {
  setUserRideBookingIdToDelete(id);
    setShow(true);
  };

  useEffect(() => {
    fetchRideBookings();
    if (rideBookings.length > 0 && dataTableRef.current) {
        $(dataTableRef.current).DataTable();
      }
    }, [rideBookings]);

    const fetchRideBookings = async () => {
        try {
            const response = await axios.get(apipath + '/booking-service/details');
            setRideBookings(response.data.booking);
        } catch (error) {
            console.error('Error fetching user ride booking:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await axios
            .delete(apipath + `/booking-service/details/${userRideBookingIdToDelete}`);
            await fetchRideBookings(); // Refresh courses after deletion
            setShow(false);
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting user ride booking:', error);
            setIsLoading(false);
        }
    };

  return (
    <>
    <SideBar />
    <Navbar />

    <section className='home'>
    <div className="toggle-sidebar" style={{display: "none"}}>
            <i className='bi bi-menu'></i>
        </div>
        
        <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User Ride Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this user ride booking?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>


        <div className="container">
            <div className="row foot-tablerow">
                <h5>User Ride Booking Details</h5>
                <div className="col-lg-11 maintable-column">
                    <div className="container mt-4">
                        <table id="datatable" className="table" ref={dataTableRef}>
                            <thead>
                                <tr> 
                                    <th>SN</th>
                                    <th>BookingOrderId</th>
                                    <th>BookingStatus</th>
                                    <th>PaymentStatus</th>
                                    <th>PaymentMethod</th>
                                    <th>RideDistance</th>
                                    <th>TotalAmount</th>
                                    <th>ServiceDate</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            {rideBookings.map((rideBooking, index) => (
                      <tr key={rideBooking._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{rideBooking.bookingOrderId}</td>
                        <td>{rideBooking.bookingStatus}</td>
                        <td>{rideBooking.paymentStatus}</td>
                        <td>{rideBooking.paymentMethod}</td>
                        <td>{rideBooking.rideDistance}</td>
                        <td>{rideBooking.totalAmount}</td>
                        <td>
                        {new Date(rideBooking.serviceDate).toLocaleDateString()}</td>
                        <td>
                        {/* <Link to={`/edit-user-safety-security/${rideBooking._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp; */}
                          <i className="fa-solid fa-trash" onClick={() => handleShow(rideBooking._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}  

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        </section>
    
    </>
  )
}

export default UserRideBookingDetails;