import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';

const ETOCoinAndDiscount = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [etoIdToDelete, setEtoIdToDelete] = useState(null);
  const [rtoCount, setEtoCount] = useState(0); // Track number of quizzes
  const [eto, setEto] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const [loading, setLoading] = useState(true);
  const maxEtoCount = 1; 

  const handleShow = (id) => {
    setEtoIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchEto();
    if (eto.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [eto]);

  const fetchEto = async () => {
    try {
      const response = await axios.get(`${apipath}/coins-distance-price/details`);
      const filtereEto = response.data.coinsADistancePrice;
      setEto(filtereEto);
      setEtoCount(filtereEto.length); // Set the current quiz count
      setLoading(false);
    } catch (error) {
      console.error('Error fetching eto:', error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/coins-distance-price/details/${etoIdToDelete}`);
      await fetchEto();
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting eto:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Eto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this eto coin and discount?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Eto Coin & Discount Details</h5>

            <div className="add-button">
              {rtoCount < maxEtoCount ? (
                <Link to="/rto-coin-discount-add">
                  <button>+ Add Eto Coin & Discount</button>
                </Link>
              ) : (
                <button disabled>+ Add Eto Coin & Discount (Max 1 Reached)</button>
              )}
            </div>

            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>ETO Coin</th>
                      <th>Distance Price</th>
                      <th>Ride Service Fee</th>
                      <th>Add On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eto.map((eto, index) => (
                      <tr key={eto._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{eto.etoCoin}</td>
                        <td>{eto.distancePrice}</td>
                        <td>{eto.rideBookingFee}</td>
                        <td>{moment(eto.createdAt).format('YYYY-MM-DD')}</td>
                        <td>
                          <Link to={`/rto-coin-discount-edit/${eto._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                          <i className="fa-solid fa-trash" onClick={() => handleShow(eto._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ETOCoinAndDiscount;
